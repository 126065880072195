@import url('https://v1.fontapi.ir/css/SFMono');
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    font-family: 'SF Mono', sans-serif;
}
html {
    scroll-behavior: smooth;
  }